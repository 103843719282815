
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("jquery-validation");
import "bootstrap";
import "./stylesheets/application";
window.$ = jQuery;
window.jQuery = jQuery;
import("./applicant_form_validation.js");
import("./candidate_form_validation.js");
import("./refer_earn_validation.js");
import("./home.js");
import $ from 'jquery'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('/app/assets/images', true)
const imagePath = (name) => images(name, true)
/*-------type writer--------------------*/
var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
    }

    setTimeout(function() {
    that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff}";
    document.body.appendChild(css);
};

/*--------------service onclick-------------*/

window.openNav = function() {
    document.getElementById("myNav").style.display ="block";
}
// function openNav() {
//     document.getElementById("myNav").style.display ="block";
//   }
  
window.closeNav = function() {
    document.getElementById("myNav").style.display ="none";
} 

window.openNavmenu = function() {
    document.getElementById("myNavmenu").style.height = "100%";
  }
  
window.closeNavmenu = function() {
    document.getElementById("myNavmenu").style.height = "0%";
  }


/*--------------apply for job--------------*/

var modal2 = document.getElementById("myModal2");
var btn = document.getElementById("myBtnId");
var btn2 = document.getElementById("myBtnId2");
var span = document.getElementsByClassName("close2")[0];

$("#myBtnId").click(function() {
  $("myModal2").css('display', 'flex');
});
$("#myBtnId2").click(function() {
  $("myModal2").css('display', 'flex');
});
$("close2").click(function() {
  $("myModal2").css('display', 'none');
});
window.click = function(event) {
  if (event.target == modal2) {
    $("myModal2").css('display', 'none');
  }
}
